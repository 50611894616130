import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Content from "../components/content"
import Footer from "../components/footer"
import SEO from "../components/seo"
import logo from "../images/logo.png"

const Page = ({data}) => {
    const page = data.graphAPI.page
    return (
        <Layout>
            <SEO title={page.title} />
                    {page.content[0].__typename !== 'GRAPHAPI_ComponentPageSplitContentImage' ? (
                        <div className="title-section">
                            <h1 className="title is-size-1">{page.title}</h1>
                        </div>
                    ) : (<></>)}
                    <Content content={page.content} />


        </Layout>
    )
}

export default Page

export const pageQuery = graphql`
    query pageById($id: ID!) {
        graphAPI {
            page(id: $id) {
                id
                title
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }

                }
            }
        } 
    }
`


